// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-guides-jsx": () => import("./../../../src/pages/guides.jsx" /* webpackChunkName: "component---src-pages-guides-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-resources-pending-jsx": () => import("./../../../src/pages/resources-pending.jsx" /* webpackChunkName: "component---src-pages-resources-pending-jsx" */),
  "component---src-pages-resources-submit-jsx": () => import("./../../../src/pages/resources-submit.jsx" /* webpackChunkName: "component---src-pages-resources-submit-jsx" */),
  "component---src-templates-tguide-jsx": () => import("./../../../src/templates/tguide.jsx" /* webpackChunkName: "component---src-templates-tguide-jsx" */),
  "component---src-templates-tresource-jsx": () => import("./../../../src/templates/tresource.jsx" /* webpackChunkName: "component---src-templates-tresource-jsx" */)
}

